import { useRouter } from 'next/router'
import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import { RoutesLink, getHref } from '../../routes'

export type BackNavButtonProps = {
  children?: string
  customDestination?: RoutesLink
}

export const BackNavButton = ({ children = 'Back', customDestination }: BackNavButtonProps) => {
  const router = useRouter()
  return (
    <button
      className="flex text-selphBlack transition-all duration-200 hover:text-selphAmber-500"
      onClick={() => (customDestination ? router.push(getHref(customDestination)) : router.back())}
    >
      <ChevronLeftIcon className="h-6 w-6" /> {children}
    </button>
  )
}

BackNavButton.displayName = 'BackNavButton'

export default BackNavButton
