import { HistoryAnalysisFragment } from '../../gql'
import { Analysis } from '../test/test-analysis'
import { fromIsoString, toStringDate } from '../../helpers/date'
import * as UI from '@/ui'

export function HistoryTimeline({ analysis }: { analysis: Analysis }) {
  if (!analysis.historicalValues || analysis.historicalValues.length < 1) {
    return null
  }

  const orderedByDate = {
    ...analysis,
    historicalValues: analysis.historicalValues.sort((a, b) => (a.sampleDate > b.sampleDate ? -1 : 1)),
  }

  return (
    <div className="max-w-min">
      <ul className="flex h-20 items-center gap-x-5 overflow-x-scroll">
        {orderedByDate.historicalValues.map((entry, i) => {
          return <Entry key={entry.sampleDate + i} entry={entry} />
        })}
      </ul>
      {orderedByDate.historicalValues.length > 1 && (
        <div className="flex justify-between">
          <span className="text-xs text-selphBlack/70 uppercase">
            Latest
            <br />
            Result
          </span>
          <span className="text-xs text-selphBlack/70 uppercase">
            Oldest
            <br />
            Result
          </span>
        </div>
      )}
    </div>
  )
}

type EntryProps = {
  entry: HistoryAnalysisFragment & {
    sampleDate: string
  }
}

const Entry = ({ entry }: EntryProps) => {
  const date = toStringDate(fromIsoString(entry.sampleDate))
  const color = UI.resultColorPicker(entry.calculatedLevel)

  return (
    <li key={entry.sampleDate + entry.calculatedLevel} id={entry.sampleDate + entry.calculatedLevel}>
      <UI.Block gap="small" display="flexCol" className="w-fit items-center justify-center">
        <UI.Paragraph size="small">{date}</UI.Paragraph>
        <UI.Badge
          color={color}
          size="small"
          className="h-6 w-24 shrink-0 text-center text-sm font-semibold whitespace-nowrap"
        >
          {entry.calculatedLevelLabel}
        </UI.Badge>
      </UI.Block>
    </li>
  )
}
