import { Fragment } from 'react'
import { colors } from './chart-colors'
import Handle from '@/images/handle.svg'
import { ScaleType, UserAnalysisFragment } from '@/gql'
import * as UI from '@/ui'

type AnalysisWithDate = UserAnalysisFragment & {
  sampleDate: string
}

export type ResultSliderProps = {
  analysis: AnalysisWithDate
}

export const ResultSlider = ({ analysis }: ResultSliderProps) => {
  const max = getMaxScalePoint(analysis)
  const min = getMinScalePoint(analysis)
  const scaleFactor =
    analysis.analysisDefinition?.scaleType === 'LOG' &&
    (analysis?.ranges?.some((range) => range.toVal != null && range.toVal < 0.1) ||
      (analysis.valueNumeric != null && analysis.valueNumeric < 0.1))
      ? 100
      : analysis.analysisDefinition?.scaleType === 'LOG' &&
          (analysis?.ranges?.some((range) => range.toVal != null && range.toVal < 1) ||
            (analysis.valueNumeric != null && analysis.valueNumeric < 1))
        ? 10
        : 1

  const tickOffsets = analysis.ranges?.map((range) =>
    offsetCalc(range.toVal, max, min, analysis.analysisDefinition?.scaleType, scaleFactor),
  )

  let direction = 'right'

  if (analysis.valueNumeric === undefined || typeof analysis.valueNumeric != 'number') return <></>

  return (
    <div className="flex w-full flex-col pt-7 pb-5">
      <div className="relative w-full">
        <Rail analysis={analysis} scaleFactor={scaleFactor} />
        {analysis.ranges
          ?.map((range) => range.toVal)
          ?.map((cutOff, i) => {
            const offset = (tickOffsets && tickOffsets[i]) || 0
            const prevOffset = (tickOffsets && tickOffsets[i - 1]) || 0
            const nextOffset = (tickOffsets && tickOffsets[i + 1]) || 0

            if (cutOff && cutOff > 0) {
              const prevGap = prevOffset > 0 ? offset - prevOffset : 0
              const nextGap = nextOffset > 0 ? nextOffset - offset : 0
              direction = direction === 'left' ? 'right' : 'left'

              return (
                <Fragment key={i}>
                  <div
                    className={cutOff != max ? 'absolute top-0 -ml-0.5 h-3 w-[3px] bg-selphWhite-100' : ''}
                    style={{ left: `${offset}%` }}
                    key={i}
                  />
                  <span
                    className={` ${
                      (prevGap > 0 && prevGap < 20) || (nextGap > 0 && nextGap < 20)
                        ? direction === 'left'
                          ? '-ml-[1.2rem]'
                          : `-ml-[0.8rem] ${
                              (prevGap > 0 && prevGap < 8) || (nextGap > 0 && nextGap < 8) ? 'mt-[-2.2rem]' : ''
                            }`
                        : '-ml-[1.1rem]'
                    } absolute top-4 w-8 text-center text-xs text-selphBlack/70`}
                    style={{ left: `${offset}%` }}
                  >
                    {cutOff}
                  </span>
                </Fragment>
              )
            }
          })}
        <Marker
          valueNumeric={analysis.valueNumeric}
          valueText={analysis.valueText}
          max={max}
          min={min}
          scale={analysis.analysisDefinition?.scaleType}
          scaleFactor={scaleFactor}
        />
      </div>
    </div>
  )
}

export const offsetCalc = (
  value?: number | null,
  max?: number | null,
  min?: number | null,
  scale?: ScaleType | null,
  scaleFactor?: number,
) => {
  if (typeof value != 'number') return
  if (typeof max != 'number') return
  if (typeof min != 'number') return
  const range = max - min
  if (scale !== 'LOG') {
    return Math.round(((value - min) / range) * 100)
  } else {
    return value - min > 0
      ? Math.round((Math.log10(value * (scaleFactor || 1)) / Math.log10(max * (scaleFactor || 1))) * 100)
      : 0
  }
}

export const getMaxScalePoint = (analysis: AnalysisWithDate) =>
  analysis.scalePoints?.find(({ type }) => type === 'MAX')?.val

export const getMinScalePoint = (analysis: AnalysisWithDate) =>
  analysis.scalePoints?.find(({ type }) => type === 'MIN')?.val

export const removeTrailingZeros = (value: number | string | undefined | null) => {
  if (!value) return
  if (typeof value === 'string' && value.includes('.')) {
    if (value.includes('<')) {
      return value.split('<')[0] + '<' + parseFloat(value.split('<')[1])
    }
    if (value.includes('>')) {
      return value.split('>')[0] + '>' + parseFloat(value.split('>')[1])
    }
    return parseFloat(value)
  }
  if (typeof value === 'number' && value.toString().includes('.')) {
    return value.toString().replace(/\.0+$/, '')
  }
  return value
}

const Marker = ({
  valueNumeric,
  valueText,
  max,
  min,
  scale,
  scaleFactor,
}: {
  valueNumeric: AnalysisWithDate['valueNumeric']
  valueText?: AnalysisWithDate['valueText']
  max?: number
  min?: number
  scaleFactor?: number
  scale?: ScaleType
}) => {
  const offset = offsetCalc(valueNumeric, max, min, scale, scaleFactor)

  return (
    <>
      <span
        className="absolute -top-[35px] left-1/2 -translate-x-1/2 text-center text-lg font-semibold whitespace-nowrap text-selphBlack"
        style={{ left: `${offset}%` }}
      >
        {removeTrailingZeros(valueText || valueNumeric)}
      </span>

      <UI.Image
        width={24}
        height={32}
        src={Handle}
        className="absolute bottom-1.5 -ml-[12px] w-6"
        style={{ left: `${offset}%`, color: '#EBE3DB' }}
        alt=""
        priority
      />
    </>
  )
}

const Rail = ({ analysis, scaleFactor }: { analysis: AnalysisWithDate; scaleFactor?: number }) => {
  const ranges = analysis.ranges
  const max = getMaxScalePoint(analysis)
  const min = getMinScalePoint(analysis)

  if (!ranges) return <></>
  const railColour: string[] = []

  ranges.forEach((range) => {
    railColour.push(
      `${range.type ? colors[range.type] : '#ffffff'} ${offsetCalc(
        range.fromVal,
        max,
        min,
        analysis.analysisDefinition?.scaleType,
        scaleFactor,
      )}% ${offsetCalc(range.toVal, max, min, analysis.analysisDefinition?.scaleType, scaleFactor)}%`,
    )
  })

  return <div className="h-3 w-full rounded-md" style={{ background: `linear-gradient(to right, ${railColour})` }} />
}
