import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceArea,
  Rectangle,
  TooltipProps,
} from 'recharts'
import { type AxisDomain } from 'recharts/types/util/types'
import { uniqBy } from 'lodash-es'
import { fromIsoString, importDate } from '../../helpers/date'
import { getMaxScalePoint, removeTrailingZeros } from './result-slider'
import { colors } from './chart-colors'
import { HistoryAnalysisFragment, ScaleType, UserAnalysisFragment } from '@/gql'
import * as UI from '@/ui'

export type Analysis = UserAnalysisFragment & {
  sampleDate: string
  historicalValues?: Array<HistoryAnalysisFragment & { sampleDate: string }>
}

export function HistoryChart({ analysis }: { analysis: Analysis }) {
  if (!analysis.historicalValues || analysis.historicalValues.length < 1) {
    return null
  }
  if (analysis.valueNumeric === null || analysis.valueNumeric === undefined) return null

  const max = getMaxScalePoint(analysis)

  const analysisRanges = analysis.ranges?.filter((range) => range.fromVal !== range.toVal) || []
  const ticks = [analysisRanges[0].fromVal as number, ...(analysisRanges.map((range) => range.toVal) as number[])]

  const { domain, range, scale } = createScale(ticks, max, analysis.analysisDefinition?.scaleType)

  const chartData = uniqBy(
    analysis.historicalValues?.map((a) => ({
      ...a,
      // account for potential 0 values on log scales
      valueNumeric:
        analysis.analysisDefinition?.scaleType === ScaleType.Log && a.valueNumeric === 0 ? 0.1 : a.valueNumeric,
      date: fromIsoString(a.sampleDate).toJSDate(),
    })),
    'sampleDate',
  )

  const existsUnderOne =
    chartData.some((data) => data.valueNumeric && data.valueNumeric < 1) || ticks.some((tick) => tick >= 0 && tick < 1)
  const existsUnderPointOne =
    chartData.some((data) => data.valueNumeric && data.valueNumeric < 0.1) ||
    ticks.some((tick) => tick >= 0 && tick < 0.1)
  const minAlternative = existsUnderPointOne ? 0.01 : existsUnderOne ? 0.1 : 1

  return (
    <ResponsiveContainer height={350}>
      <LineChart margin={{ top: 10, left: -10 }} data={chartData}>
        <CartesianGrid stroke="#c7c5b6" vertical={false} />
        <XAxis
          axisLine={false}
          dataKey="date"
          scale="point"
          tickFormatter={(tick) => importDate(tick).toLocaleString({ month: 'short', year: 'numeric' })}
          padding={{ left: 10, right: 10 }}
          tickSize={0}
          tickMargin={10}
          fontSize={12}
        />

        <YAxis
          axisLine={false}
          tickLine={false}
          ticks={ticks.filter((tick) => {
            if (analysis.analysisDefinition?.scaleType !== ScaleType.Log) {
              return true
            }

            return tick > 0 ? true : false
          })}
          tickMargin={15}
          tickFormatter={(tick) => (tick == domain[0] ? '' : tick)}
          type="number"
          scale={scale}
          domain={domain}
          range={range}
          color="#FF0000"
          interval={0}
          fontSize={12}
        />
        <Line strokeDasharray="4" />
        {analysisRanges.map((range) => {
          let fromValue = range.fromVal
          if (analysis.analysisDefinition?.scaleType === ScaleType.Log && range.fromVal === 0) {
            fromValue = minAlternative
          }
          const fromOffset = fromValue as number
          const toOffset = range.toVal as number

          return (
            <ReferenceArea
              key={range.fromVal}
              y1={fromOffset}
              y2={toOffset}
              ifOverflow={'extendDomain'}
              fill={range.type ? colors[range.type] : '#ffffff'}
              fillOpacity={1}
              stroke="#d4dbe4"
              strokeWidth={1}
              radius={5}
              shape={(props) => <Rectangle {...props} width={10} x={props.x - 25} />}
              className="w-3 overflow-hidden"
            />
          )
        })}
        <Tooltip cursor={false} content={<CustomChartTooltip />} offset={0} />
        <Line
          type="monotone"
          dataKey="valueNumeric"
          stroke="#fff"
          strokeWidth={2}
          dot={{
            fill: '#000',
            stroke: '#000',
          }}
          fill="#fff"
          activeDot={{ stroke: '#000', strokeWidth: 4, r: 6 }}
        />
      </LineChart>
    </ResponsiveContainer>
  )
}

const createScale = (ticks: number[], max: number | undefined, scaleType: ScaleType | undefined) => {
  // Determine the domain of your data
  const minTick = Math.min(...ticks)

  const existsUnderOne = ticks.some((tick) => tick >= 0 && tick <= 1)
  const existsUnderPointOne = ticks.some((tick) => tick >= 0 && tick <= 0.1)
  const minAlternative = existsUnderPointOne ? 0.01 : existsUnderOne ? 0.1 : 1

  const domain: AxisDomain =
    scaleType === ScaleType.Log ? [Math.max(minAlternative, minTick), max || 'dataMax'] : [minTick, max || 'dataMax']
  const range = [minTick, max as number]
  const scale = scaleType === ScaleType.Log ? 'log' : 'linear'

  return { domain, range, scale } as const
}

const CustomChartTooltip = ({ active, payload }: TooltipProps<number, string>) => {
  if (active && payload && payload.length) {
    const analysis = payload[0].payload
    const color = UI.resultColorPicker(analysis.calculatedLevel)

    return (
      <div className="flex flex-col justify-center gap-2 rounded-lg bg-selphWhite-500 p-2 shadow-sm">
        {/* eslint-disable-next-line no-restricted-syntax */}
        <p className="text-center">{new Date(analysis.date).toLocaleDateString()}</p>
        <UI.Badge
          color={color}
          padding="small"
          className="h-6 w-24 shrink-0 text-center text-sm font-semibold whitespace-nowrap"
        >
          {analysis.calculatedLevelLabel}
        </UI.Badge>
        <p className="text-center">
          {removeTrailingZeros(analysis.valueText || analysis.valueNumeric)}
          <span className="pl-1 text-sm"> {analysis.analysisDefinition?.units}</span>
        </p>
      </div>
    )
  }

  return null
}
